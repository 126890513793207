<template>
  <div class="ui-drawer">
    <div class="drawer-wrap">
      <div
        class="btn-trigger btn05"
        @click="hamburgerSet"
        :class="{
          active: active === true,
          '': active === false,
        }"
      >
        <span></span>
        <span></span>
      </div>
      <div></div>
      <div
        class="drawer-menu"
        :class="{
          'open-drawer': openDrawer === true,
          'close-drawer': openDrawer === false,
        }"
      >
        <div class="drawer-header"></div>
        <div @click="hamburgerSet" class="drawer-body">
          <div class="drawer-item">
            <router-link to="/" v-scroll-to="'#app'">トップページ</router-link>
          </div>
          <div class="drawer-item">
            <router-link to="/used-car" v-scroll-to="'#app'">中古車販売・買取</router-link>
          </div>
          <div class="drawer-item">
            <router-link to="/insurance" v-scroll-to="'#app'">保険</router-link>
          </div>
          <div class="drawer-item">
            <router-link to="/rent-a-car" v-scroll-to="'#app'">レンタカー</router-link>
          </div>
          <div class="drawer-item">
            <router-link to="/maintenance" v-scroll-to="'#app'">車検・整備</router-link>
          </div>
          <div class="drawer-item">
            <a href="https://rci85.com" target="_blank">自動車陸送</a>
          </div>
          <div class="drawer-item">
            <router-link to="/shop" v-scroll-to="'#app'">会社・店舗紹介</router-link>
          </div>
        </div>
      </div>
    </div>
    <div
      @click="hamburgerSet"
      class="back-gray"
      :class="{ 'back-gray-show': openDrawer === true }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "UiDrawer",
  data() {
    return {
      openDrawer: false,
      active: false,
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {
    switchDrawer() {
      this.openDrawer = this.openDrawer === false ? true : false;
    },
    hamburgerButton() {
      this.active = this.active === false ? true : false;
    },
    hamburgerSet() {
      this.switchDrawer();
      this.hamburgerButton();
    },
  },
};
</script>

<style lang="scss" scoped>
$width: 280px;
.drawer-wrap {
  .drawer-button {
    width: 50px;
    cursor: pointer;
  }
  .drawer-menu {
    transition: all 1s 0s ease;
    position: fixed;
    top: 0;
    background-color: var(--sub1);
    opacity: 0.8;
    // padding: 5px 10px 50px 10px;//普通のリストは復活
    box-sizing: border-box;
    z-index: 999;
    width: $width;
    height: 100vh;

    .drawer-body {
      padding-top: 50px;
      .drawer-item {
        text-align: initial;
        transition: all 1s;
        padding: 12px 10px;
        transition: all 0.5s; //普通のリストは消す
        position: relative; //普通のリストは消す
        z-index: 2; //普通のリストは消す
        @media screen and (max-width: 767px) {
          padding: 7px 10px;
        }
        &:hover {
          cursor: pointer;
        }
        span {
          margin-left: 10px;
        }

        a {
          width: 100%;
        }

        a,
        span {
          text-decoration: none;
          color: var(--white);
          position: relative;
          display: inline-block;
          &:hover {
            color: var(--main);
          }
        }
      }
    }
  }
}
.open-drawer {
  opacity: 1;
  transform: translateX(-$width);
  right: -$width;
}
.close-drawer {
  opacity: 0;
  transition: opacity 3s linear 2s;
  transform: translateX($width);
  right: -$width;
}

.back-gray {
  display: none;
  position: fixed;
  z-index: 998;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.5;
}
.back-gray-show {
  display: initial;
}

.btn-trigger {
  position: relative;
  width: 38px;
  height: 44px;
  cursor: pointer;
  z-index: 1000;
}
.btn-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--white);
  border-radius: 3px;
}
.btn-trigger,
.btn-trigger span {
  display: inline-block;
  transition: all 0.5s;
  box-sizing: border-box;
}
.btn-trigger span:nth-of-type(1) {
  top: 20px;
  left: 10px;
}
.btn-trigger span:nth-of-type(2) {
  top: 30px;
  // top: 10px;
}

.btn-trigger span:nth-of-type(1) {
  animation: menu-bar01 0.75s forwards;
}
@keyframes menu-bar01 {
  0% {
    transform: translateY(20px) rotate(45deg);
  }
  50% {
    transform: translateY(20px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

.btn-trigger.active span:nth-of-type(1) {
  animation: active-menu-bar01 0.75s forwards;
  background-color: white;
}
@keyframes active-menu-bar01 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(0px) rotate(0);
  }
  100% {
    transform: translateY(3px) translateX(-10px) rotate(45deg);
  }
}
.btn-trigger.active span:nth-of-type(2) {
  background-color: white;
  animation: active-menu-bar03 0.75s forwards;
}

@keyframes active-menu-bar03 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-10px) rotate(0);
  }
  100% {
    transform: translateY(-8px) rotate(-45deg);
  }
}
.kakko {
  font-size: 14px;
  margin-left: 5px;
}
</style>
