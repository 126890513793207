<template>
  <div class="feature">
    <img :src="require(`@/assets/Ui/${imgPath}`)" :alt="imgAlt" class="feature-logo pc-tablet-only" />
    <div class="feature-message">
      <h3 class="feature-title">
        <img :src="require(`@/assets/Ui/${imgPath}`)" :alt="imgAlt" class="feature-logo sp-only" />
        <span v-html="title"></span>
      </h3>
      <p class="feature-description" v-html="description"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeaturesVue",
  props: {
    imgPath: String,
    imgAlt: String,
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
  }
}
</script>

<style lang="scss" scoped>
  .feature {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    margin-bottom: 20px;
    background-color: var(--sub2);
    .feature-logo {
      width: 10%;
      height: auto;
      aspect-ratio: 33 / 37;
      margin: auto 0;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 15%;
      }
      @media screen and (max-width: 767px) {
        width: 20%;
        margin-right: 5%;
      }
    }
    .feature-message {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      width: 60%;
      height: auto;
      padding-left: 5%;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 70%;
      }
      @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
        padding: 0 20px;
      }
      .feature-title {
        width: 100%;
        font-size: 23px;
        color: var(--sub1);
        margin-top: 0;
        margin-bottom: 10px;
        @media screen and (max-width: 767px) {
          display: flex;
          align-items: center;
          font-size: 20px;
        }
      }
      .feature-description {
        width: 100%;
        margin: 0;
      }
    }
  }
</style>