<template>
  <div>
    <PageTitle :title="title" :description="description" />
    <TopSwiperVue />
    <OurService />
    <ServiceList />
    <Flow />
    <ContactVue />
  </div>
</template>

<script>
import PageTitle from '@/components/Ui/PageTitle.vue';
import TopSwiperVue from '@/components/UsedCar/TopSwiper.vue';
import OurService from '@/components/UsedCar/OurService.vue';
import ServiceList from '@/components/UsedCar/ServiceList.vue';
import Flow from '@/components/UsedCar/Flow.vue';
import ContactVue from "@/components/Top/Contact.vue";

export default {
  name: "UsedCar",
  components: {
    PageTitle,
    TopSwiperVue,
    OurService,
    ServiceList,
    Flow,
    ContactVue,
  },
  data() {
    return {
      title: "Purchase and sale <br class='sp-only'>of used cars",
      description: "SAVAGEの中古車の販売・買取",
    }
  },
}
</script>

<style lang="scss" scoped>
</style>