<template>
  <section>
    <div class="wrap">
      <div class="content">
        <img src="@/assets/maintenance/top.webp" alt="SAVAGEの車検・整備は無駄なコストを削減し充実なフォロー体制をご用意しています" class="top-img">
        <p class="top-message">
          車検・自動車整備もSAVAGEにお任せください。無駄なコストをかけず、充実したフォロー体制をご用意して車検と自動車整備を対応しています。お客様のお車を大切に取扱させていただいています。
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "InsuranceTop"
}
</script>

<style lang="scss" scoped>
.content {
  @media screen and (max-width: 767px) {
    padding: 0;
  }
  .top-message {
    margin-top: 40px;
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
      margin-top: 20px;
    }
  }
}
</style>