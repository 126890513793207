<template>
  <div>
    <PageTitle :title="title" :description="description" />
    <Top />
    <Service />
    <FeaturesVue />
    <FlowVue />
    <ContactVue />
  </div>
</template>

<script>
import PageTitle from '@/components/Ui/PageTitle.vue';
import Top from '@/components/Maintenance/Top.vue';
import Service from '@/components/Maintenance/OurService.vue';
import FeaturesVue from '@/components/Maintenance/Features.vue';
import FlowVue from "@/components/Maintenance/Flow.vue";
import ContactVue from "@/components/Top/Contact.vue";

export default {
  name: "MaintenanceVue",
  components: {
    PageTitle,
    Top,
    Service,
    FeaturesVue,
    FlowVue,
    ContactVue,
  },
  data() {
    return {
      title: "Vehicle inspection <br class='sp-only' />& maintenance",
      description: "SAVAGEの車検・自動車整備サービス",
    }
  },
}
</script>

<style lang="scss" scoped>
</style>