<template>
  <Header />
  <router-view/>
  <img
    src="@/assets/top/to-top.webp"
    alt="TOPへ戻る"
    class="top-scroll"
    v-if="showScrollButton"
    v-scroll-to="'#app'"
  />
  <Footer />
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/Layouts/Header.vue";
import Footer from '@/components/Layouts/Footer.vue';

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      showScrollButton: false,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    path() {
      return this.$route.path;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    // コンポーネントが破棄される前にイベントリスナーを削除
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // ページのスクロール位置を取得
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      // スクロール位置が一定値以上の場合にスクロールボタンを表示
      this.showScrollButton = scrollTop > 200;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");

/*---------------------
 共通初期設定
---------------------*/
:root {
  --main: #000000;
  --sub1: #737373;
  --sub2: #E9E9E9;
  --white: #FFFFFF;
  --goonet: #CE0808;
  --goonet-shadow: #ffa8a8;
}
#app {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  color: var(--font);
  line-height: 1.7em;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    margin-top: 0;
  }
}
html * {
  box-sizing: border-box !important;
}
body {
  margin: 0;
}
img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}
a {
  text-decoration: none;
  color: var(--font);
  cursor: pointer;
}
/*---------------------
 Section
---------------------*/
section {
  padding: 150px 0;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding: 80px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 60px 0;
  }
  h2 {
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    color: var(--font);
    margin: 0;
    @media screen and (max-width: 1100px) {
      font-size: 20px;
      font-weight: 500;
    }
    hr {
      width: 45px;
      margin: 30px auto 60px auto;
      border-color: var(--main);
      @media screen and (max-width: 1100px) {
        margin: 10px auto 30px auto;
      }
    }
  }
  .sub-title {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    background-color: var(--gray);
    h3 {
      color: var(--white);
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
  }
}
.wrap {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  @media screen and (max-width: 1100px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  h2 {
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    color: var(--font);
    margin: 0;
    @media screen and (max-width: 1100px) {
      font-size: 21px;
      font-weight: 500;
    }
    hr {
      width: 45px;
      margin: 30px auto 60px auto;
      border-color: var(--main);
      @media screen and (max-width: 1100px) {
        margin: 10px auto 20px auto;
      }
    }
  }
  .content {
    width: 800px;
    margin: 0 auto;
    @media screen and (max-width: 1100px) {
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
      margin: 0;
    }
  }
}
/*---------------------
 TOPへ戻るスクロールボタン
---------------------*/
.top-scroll {
  width: 70px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 3;
  opacity: 0.9;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    width: 50px;
    right: 0;
    bottom: 60px;
  }
}
/*---------------------
 電話番号 クリック設定
---------------------*/
.phone-number {
  @media screen and (min-width: 768px) {
    // 現話番号のクリックを無効化する
    display: inline;
    text-wrap: nowrap;
    pointer-events: none;
    cursor: default;
  }
}
/*---------------------
 display 設定
---------------------*/
.pc-only {
  @media screen and (max-width: 1100px) {
    display: none;
  }
}
.pc-tablet-only {
  display: none;
  @media screen and (min-width: 768px) {
    display: initial !important;
  }
}
.tablet-only {
  display: none;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    display: initial !important;
  }
}
.sp-only {
  display: none;
  @media screen and (max-width: 767px) {
    display: initial !important;
  }
}
/*---------------------
 ボタンアニメーション
---------------------*/
.animation-btn {
  /*アニメーションの起点とするためrelativeを指定*/
  position: relative;
  overflow: hidden;
  /*アニメーションの指定*/   
  transition: ease .2s;
}
/*ボタン内spanの形状*/
.animation-btn span {
  position: relative;
  z-index: 3;
  & :hover {
    color: var(--main);
  }
}
/*== 背景が流れる（斜め） */
.bgskew::before {
  content: '';
    /*絶対配置で位置を指定*/
  position: absolute;
  top: 0;
  left: -130%;
    /*色や形状*/
  background: var(--white);
  width:120%;
  height: 100%;
  transform: skewX(-25deg);
}
/*hoverした時のアニメーション*/
.bgskew:hover::before {
  animation: skewanime .5s forwards;/*アニメーションの名前と速度を定義*/
}
@keyframes skewanime {
  100% {
    left:-10%;/*画面の見えていない左から右へ移動する終了地点*/
  }
}

</style>
