<template>
  <TopVideo />
  <AboutSavage />
  <OurService />
  <FeaturesVue />
  <RepresentativeMessage />
  <ShopInformation />
  <FlowVue />
  <ContactVue />
</template>
<script>
import TopVideo from "@/components/Top/Top.vue";
import AboutSavage from "@/components/Top/AboutSavage.vue";
import OurService from "@/components/Top/OurService.vue";
import FeaturesVue from "@/components/Top/Features.vue";
import RepresentativeMessage from "@/components/Top/RepresentativeMessage.vue";
import ShopInformation from "@/components/Top/ShopInformation.vue";
import FlowVue from "@/components/Top/Flow.vue";
import ContactVue from "@/components/Top/Contact.vue";

export default {
  name: "TopVue",
  components: {
    TopVideo,
    AboutSavage,
    OurService,
    FeaturesVue,
    RepresentativeMessage,
    ShopInformation,
    FlowVue,
    ContactVue,
  }
}
</script>

<style lang="scss" scoped>
</style>