<template>
  <div class="page-title">
    <img src="@/assets/Ui/title_background.webp" alt="ページタイトル背景" class="background pc-only">
    <img src="@/assets/Ui/title_background_sp.webp" alt="ページタイトル背景" class="background sp-only tablet-only">
    <div class="content">
      <h1 class="title" v-html="title"></h1>
      <p class="description" v-html="description"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
  }
}
</script>

<style lang="scss" scoped>
  .page-title {
    position: relative;
    height: 200px;
    margin-top: 75px;
    @media screen and (max-width: 767px) {
      margin-top: 75px;
      height: 165px;
    }
    .background {
      height: 200px;
      @media screen and (max-width: 767px) {
        height: 165px;
      }
    }
    .content {
      position: absolute;
      top:  50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: auto;
      .title,
      .description {
        text-align: center;
        white-space: nowrap;
      }
      .title {
        font-size: 42px;
        line-height: 45px;
        font-weight: 700;
        margin: 0;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          font-size: 32px;
          line-height: 35px;
        }
        @media screen and (max-width: 767px) {
          font-size: 28px;
          line-height: 35px;
        }
      }
      .description {
        font-size: 18px;
        margin: 10px 0 0 0;
      }
    }
  }
</style>