<template>
  <section>
    <div class="wrap">
      <div class="content">
        <img src="@/assets/insurance/top.webp" alt="SAVAGEではお客様のご要望に合わせたオーダーメイドプランをご提案します" class="top-img">
        <p class="top-message">
          岐阜市・羽島市にショップがあるSAVAGE（サベージ）では、保険（自動車/損害/生命）のオーダーメイドプランをご提案いたします。お客様に本当に必要な保険を二人三脚で作り上げるプランで、万が一の場合にも安心をお約束します。中古車の購入とあわせてぜひご依頼ください。
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "InsuranceTop"
}
</script>

<style lang="scss" scoped>
.content {
  @media screen and (max-width: 767px) {
    padding: 0;
  }
  .top-message {
    margin-top: 40px;
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
      margin-top: 20px;
    }
  }
}
</style>