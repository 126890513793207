import { createRouter, createWebHistory } from "vue-router";
import { setTitle, setDescription } from "@/mixins/index.js";
import store from "@/store";

import TopView from "@/views/Top.vue";
import UsedCar from "@/views/UsedCar.vue";
import InsuranceVue from "@/views/Insurance.vue";
import RentACar from "@/views/RentACar.vue";
import MaintenanceVue from "@/views/Maintenance.vue";
import StoreVue from "@/views/Store.vue";

const routes = [
  {
    path: '/',
    name: 'Top',
    component: TopView,
    meta: {
      title: "SAVAGE（サベージ）中古車の販売・買取、保険、レンタカー、車検・整備",
      description:
        "株式会社R.C.I.が運営する自動車関連サービスSAVAGE（サベージ）では、中古車の販売・買取、保険、レンタカー、車検・整備など自動車に関するサービスを多岐に渡って展開しています。拠点は岐阜市・羽島市にあります。お客様一人ひとりを大切にし、お値打ち価格と丁寧な対応をお約束します。",
    },
  },
  {
    path: '/used-car',
    name: 'UsedCar',
    component: UsedCar,
    meta: {
      title: "中古車の販売・買取｜岐阜市・羽島市のSAVAGE",
      description:
        "岐阜市・羽島市にショップがあるSAVAGE（サベージ）は、中古車の販売、お客様がほしいお車をオークションで仕入れて販売するオークション代行、中古車の買取、新車の販売を行っています。中古車をお求めの方は、他店と価格を比較してみてください。価格に自信があります。ぜひお気軽にお問い合わせ・ご相談ください。",
    },
  },
  {
    path: '/insurance',
    name: 'Insurance',
    component: InsuranceVue,
    meta: {
      title: "各種保険｜岐阜市・羽島市の自動車ショップSAVAGE",
      description:
        "岐阜市・羽島市にショップがあるSAVAGE（サベージ）では、保険（自動車/損害/生命）のオーダーメイドプランをご提案いたします。お客様に本当に必要な保険を二人三脚で作り上げるプランで、万が一の場合にも安心をお約束します。中古車の購入とあわせてぜひご依頼ください。",
    },
  },
  {
    path: '/rent-a-car',
    name: 'RentACar',
    component: RentACar,
    meta: {
      title: "高級車・輸入車レンタカー｜岐阜・羽島の自動車ショップSAVAGE",
      description:
        "岐阜市・羽島市にショップがあるSAVAGE（サベージ）のレンタカーサービスは、軽自動車や輸入車など幅広い車種からお客様が乗りたい、乗ってみたい車を選んでレンタルしていただくことができます。SAVAGEならではなの珍しい高級車をレンタカーとしてご用意しています。SAVAGEのレンタカー日々の生活にちょっとした楽しみを取り入れてみてください。",
    },
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: MaintenanceVue,
    meta: {
      title: "車検・整備｜岐阜市・羽島市の自動車ショップSAVAGE",
      description:
        "車検・自動車整備もSAVAGEにお任せください。無駄なコストをかけず、充実したフォロー体制をご用意して車検と自動車整備を対応しています。お客様のお車を大切に取扱させていただいています。",
    },
  },
  {
    path: '/shop',
    name: 'Shop',
    component: StoreVue,
    meta: {
      title: "会社・店舗紹介｜岐阜市・羽島市の自動車ショップSAVAGE",
      description:
        "岐阜市・羽島市に拠点を置き、中古車の販売・買取、保険、レンタカー、車検・整備を行うSAVAGE（サベージ）の会社情報と店舗情報です。",
    },
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition === true) {
      return savedPosition;
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach((to, from, next) => {
  let documentHead = document.getElementsByTagName("head");
  let gfjScript = document.createElement("script");
  gfjScript.type = "application/ld+json";
  if (to.meta.gfjScriptContent !== undefined) {
    gfjScript.innerHTML = to.meta.gfjScriptContent;
    documentHead[0].appendChild(gfjScript);
    //↑gfj用
  }
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");

  if (to.path.slice(-1) === "/") {
    next();
  } else {
    next(to.path + "/");
  }
});

export default router;
