<template>
  <section>
    <SectionTitle :title="title" subTitle="料金について" :black="true" />
    <div class="wrap">
      <p class="price-description">SAVAGEのレンタカーサービスは、他社よりもお値打ち価格です。<br class="tablet-only">以下のボタンから車種と料金をご確認できます。</p>
      <a href="/pdf/savage_rent_a_car_price.pdf" target="_blank" class="price-table animation-btn bgskew"><span>レンタカーの料金表を見る</span></a>
    </div>
  </section>
</template>

<script>
import SectionTitle from '@/components/Ui/SectionTitle.vue';

export default {
  name: "PriceVue",
  components: {
    SectionTitle,
  },
  data() {
    return {
      title: "Price",
    }
  },
}
</script>

<style lang="scss" scoped>
  section {
    background-color: var(--main);
    .wrap {
      .price-description {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        line-height: 2rem;
        color: var(--white);
        @media screen and (max-width: 767px) {
          font-size: 18px;
          text-align: start;
        }
      }
      .price-table {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 440px;
        height: 60px;
        font-size: 18px;
        font-weight: 500;
        color: var(--white);
        border: 1px solid var(--white);
        margin: 40px auto 0 auto;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          width: 55%;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          font-size: 16px;
        }
        &:hover {
          color: var(--main);
        }
      }
    }
  }
</style>