<template>
  <section>
    <div class="wrap">
      <SectionTitle :title="title" subTitle="SAVAGEの自動車/損害/生命保険の特徴" :black="false" />
      <div class="features">
        <UiFeatures 
          v-for="feature in features" 
          :key="feature.id" 
          :imgPath="feature.imgPath"
          :imgAlt="feature.imgAlt"
          :title="feature.title"
          :description="feature.description"
        />
      </div>
    </div>
  </section>
</template>

<script>
import SectionTitle from '@/components/Ui/SectionTitle.vue';
import UiFeatures from '@/components/Ui/Features.vue';

export default {
  name: "FeaturesVue",
  components: {
    SectionTitle,
    UiFeatures,
  },
  data() {
    return {
      title: "Features",
      features: [
        {
          id: 1,
          imgPath: "insurance_01.webp",
          imgAlt: "SAVAGEの保険の特徴 1",
          title: "オーダーメイドプラン",
          description: "お客様のニーズに合った最適なプランを一緒に作り上げます。お客様が損をしない方法をご提案できます。"
        },
        {
          id: 2,
          imgPath: "insurance_02.webp",
          imgAlt: "SAVAGEの保険の特徴 2",
          title: "土日も事故対応",
          description: "土曜日、日曜日の事故にも対応可能です。"
        },
        {
          id: 3,
          imgPath: "insurance_03.webp",
          imgAlt: "SAVAGEの保険の特徴 3",
          title: "専門知識のある<br class='sp-only' />保険専属スタッフ",
          description: "専門知識のある保険専属スタッフによるフォロー体制がしっかりしています。"
        },
        {
          id: 4,
          imgPath: "insurance_04.webp",
          imgAlt: "SAVAGEの保険の特徴 4",
          title: "代車が豊富",
          description: "指定工場があるため、代車が豊富です。レンタカーの手配も安心です。"
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
section {
  .wrap {
    .features {
      .feature {
        display: flex;
        justify-content: center;
        padding: 20px 0;
        margin-bottom: 20px;
        background-color: var(--sub2);
        .feature-logo {
          width: 15%;
          height: auto;
          aspect-ratio: 33 / 37;
          margin: auto 0;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            width: 18%;
          }
          @media screen and (max-width: 767px) {
            width: 20%;
            margin-right: 5%;
          }
        }
        .feature-message {
          width: 60%;
          height: auto;
          padding-left: 5%;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            width: 70%;
          }
          @media screen and (max-width: 767px) {
            width: auto;
            padding: 0 20px;
          }
          .feature-title {
            font-size: 23px;
            color: var(--sub1);
            @media screen and (max-width: 767px) {
              display: flex;
              align-items: center;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
</style>