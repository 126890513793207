<template>
  <section>
    <div class="wrap">
      <SectionTitle :title="title" subTitle="SAVAGEの特徴" :black="false" />
      <div class="features">
        <div class="feature">
          <img src="@/assets/top/features_1.webp" alt="SAVAGEの特徴 1" class="feature-logo pc-tablet-only">
          <div class="feature-message">
            <h3 class="feature-title">
              <img src="@/assets/top/features_1.webp" alt="SAVAGEの特徴 1" class="feature-logo sp-only">
              自動車関連の<br class="sp-only">広いサービスを展開
            </h3>
            <p class="feature-description">
              SAVAGEは、中古車の販売・買取、自動車保険をはじめとする各種保険、レンタカー、車検・自動車整備など、自動車に関する幅広いサービスを行っています。各サービスの相互作用によって、低コストで効率的なサービスをお客様にご提供することができます。
            </p>
          </div>
        </div>
        <div class="feature">
          <img src="@/assets/top/features_2.webp" alt="SAVAGEの特徴 2" class="feature-logo pc-tablet-only">
          <div class="feature-message">
            <h3 class="feature-title">
              <img src="@/assets/top/features_2.webp" alt="SAVAGEの特徴 2" class="feature-logo sp-only">
              お客様目線の<br class="sp-only">対応と提案
            </h3>
            <p class="feature-description">
              SAVAGEはお客様との出会いを大切にしています。お客様一人ひとりのご希望・ご要望を最優先に考え、ご満足いただけるよう、丁寧で誠実な業務対応を方針としています。また、迅速な対応も方針としています。「SAVAGEにお願いしてよかった」と思っていただける、そんなサービスと対応を提供しています。
            </p>
          </div>
        </div>
        <div class="feature">
          <img src="@/assets/top/features_3.webp" alt="SAVAGEの特徴 3" class="feature-logo pc-tablet-only">
          <div class="feature-message">
            <h3 class="feature-title">
              <img src="@/assets/top/features_3.webp" alt="SAVAGEの特徴 3" class="feature-logo sp-only">
              安心・安全をお約束
            </h3>
            <p class="feature-description">
              自動車に関連する事業をフルラインで行うにおいて、お客様に「安心と安全」をお約束しています。そのために、自動車の状態のチェックやスタッフ教育をしっかりと行っています。このような体制でサービスを提供していますので、安心してお仕事のご依頼をしていただくことができます。
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SectionTitle from '@/components/Ui/SectionTitle.vue';

export default {
  name: "FeaturesVue",
  components: {
    SectionTitle,
  },
  data() {
    return {
      title: "Features",
    }
  },
}
</script>

<style lang="scss" scoped>
section {
  .wrap {
    .features {
      .feature {
        display: flex;
        justify-content: center;
        padding: 20px 0;
        margin-bottom: 20px;
        background-color: var(--sub2);
        .feature-logo {
          width: 12%;
          height: auto;
          aspect-ratio: 33 / 37;
          margin: auto 0;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            width: 17%;
          }
          @media screen and (max-width: 767px) {
            width: 20%;
            margin-right: 5%;
          }
        }
        .feature-message {
          width: 60%;
          height: auto;
          padding-left: 5%;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            width: 70%;
          }
          @media screen and (max-width: 767px) {
            width: auto;
            padding: 0 20px;
          }
          .feature-title {
            font-size: 23px;
            color: var(--sub1);
            margin-bottom: 10px;
            @media screen and (max-width: 767px) {
              display: flex;
              align-items: center;
              font-size: 20px;
            }
          }
          .feature-description {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>