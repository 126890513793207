<template>
  <section>
    <div class="wrap">
      <SectionTitle :title="title" subTitle="SAVAGEのレンタカーサービスの特徴" :black="false" />
      <div class="features">
        <UiFeatures 
          v-for="feature in features" 
          :key="feature.id" 
          :imgPath="feature.imgPath"
          :imgAlt="feature.imgAlt"
          :title="feature.title"
          :description="feature.description"
        />
      </div>
    </div>
  </section>
</template>

<script>
import SectionTitle from '@/components/Ui/SectionTitle.vue';
import UiFeatures from '@/components/Ui/Features.vue';

export default {
  name: "FeaturesVue",
  components: {
    SectionTitle,
    UiFeatures,
  },
  data() {
    return {
      title: "Features",
      features: [
        {
          id: 1,
          imgPath: "insurance_01.webp",
          imgAlt: "SAVAGEのレンタカーの特徴 1",
          title: "珍しい車も選べる",
          description: "普通の車と珍しい車を選ぶことができます。通常のレンタカー店にはない、普段あまり乗れないような維持費がかかる輸入車に乗ってアウトドアや街乗りを体験できます。MT（ミッション車）取扱店です。"
        },
        {
          id: 2,
          imgPath: "insurance_02.webp",
          imgAlt: "SAVAGEのレンタカーの特徴 2",
          title: "初心者OK",
          description: "初心者も通常料金で利用できます。初心者の方も、安心してレンタルすることができます。"
        },
        {
          id: 3,
          imgPath: "insurance_03.webp",
          imgAlt: "SAVAGEのレンタカーの特徴 3",
          title: "低コスト",
          description: "レンタカーのフランチャイズに加盟せず、中古車をレンタカーとして使用することで格安提供しております。"
        },
        {
          id: 4,
          imgPath: "insurance_04.webp",
          imgAlt: "SAVAGEのレンタカーの特徴 4",
          title: "レンタカー宅配サービス",
          description: "陸送費をいただければ、レンタカーをご自宅までお届けできます。"
        },
        {
          id: 5,
          imgPath: "insurance_05.webp",
          imgAlt: "SAVAGEのレンタカーの特徴 5",
          title: "ハイシーズン料金なし",
          description: "ハイシーズンも通常と同じお値段でレンタルいただけます。"
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
section {
  .wrap {
    .features {
      .feature {
        display: flex;
        justify-content: center;
        padding: 20px 0;
        margin-bottom: 20px;
        background-color: var(--sub2);
        .feature-logo {
          width: 15%;
          height: auto;
          aspect-ratio: 33 / 37;
          margin: auto 0;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            width: 18%;
          }
          @media screen and (max-width: 767px) {
            width: 20%;
            margin-right: 5%;
          }
        }
        .feature-message {
          width: 60%;
          height: auto;
          padding-left: 5%;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            width: 70%;
          }
          @media screen and (max-width: 767px) {
            width: auto;
            padding: 0 20px;
          }
          .feature-title {
            font-size: 23px;
            color: var(--sub1);
            @media screen and (max-width: 767px) {
              display: flex;
              align-items: center;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
</style>