<template>
  <section>
    <div id="used-car" class="wrap">
      <div class="service-content first">
        <div class="service-title">
          <img src="@/assets/usedcar/bracket_start.webp" alt="かっこ" class="bracket">
          <h2>中古車販売</h2>
          <img src="@/assets/usedcar/bracket_end.webp" alt="かっこ閉じる" class="bracket">
        </div>
        <p class="service-description">
          高級車を専門に他社よりもお値打ち価格で中古車の販売を行っています。30日間の車両返品保証などお客様のご予算に合わせて柔軟にご提案しています。店舗は岐阜羽島IC、東海道新幹線の岐阜羽島駅からすぐにあるため、遠方からもアクセスしやすいです。店舗に来店いただかないオンライン購入にも対応しています。
        </p>
      </div>
      <div class="used-car-list">
        <img src="@/assets/usedcar/005_01.webp" alt="TOYOTA RAV4" class="used-car">
        <img src="@/assets/usedcar/005_02.webp" alt="MINI クロスオーバ" class="used-car">
        <img src="@/assets/usedcar/005_03.webp" alt="VOLVO V40" class="used-car">
        <img src="@/assets/usedcar/005_04.webp" alt="TOYOTA ランドクルーザー" class="used-car">
        <img src="@/assets/usedcar/005_05.webp" alt="MERCEDES-BENZ G350" class="used-car">
        <img src="@/assets/usedcar/005_06.webp" alt="RANGEROVER クラシックレンジ" class="used-car">
      </div>
      <a href="https://www.goo-net.com/usedcar_shop/0209009/detail.html" target="_blank" class="btn-goonet pop">
        <!-- <span>グーネットで販売中の車を見てみる</span> -->
         <img src="@/assets/usedcar/goonet-logo.png.webp" alt="グーネットで販売中の車を見てみる">
      </a>
      <p class="goonet-text">グーネットで販売中の車を見てみる</p>

      <div id="auction" class="service-content">
        <div class="service-title">
          <img src="@/assets/usedcar/bracket_start.webp" alt="かっこ" class="bracket">
          <h2 class="auction">オークション代行</h2>
          <img src="@/assets/usedcar/bracket_end.webp" alt="かっこ閉じる" class="bracket">
        </div>
        <div class="service-description">
          <p>お客様がほしいお車、乗ってみたいお車をオークションで仕入れて、整備して販売しています。在庫を持たず、オークションで仕入れることによってお値打ち価格でのご提供が可能です。30日間の車両返品保証を行っています（※保証はお客様のご予算に合わせて提案しています）。当社にお越しいただくことなく、オンライン完結で購入することもできます。</p>
          <img src="@/assets/usedcar/service_02.webp" alt="オークション会場の様子">
        </div>
      </div>

      <div id="purchase" class="service-content">
        <div class="service-title">
          <img src="@/assets/usedcar/bracket_start.webp" alt="かっこ" class="bracket">
          <h2>自動車買取</h2>
          <img src="@/assets/usedcar/bracket_end.webp" alt="かっこ閉じる" class="bracket">
        </div>
        <p class="service-description">
          新しいお車を購入する際に、現在お乗りのお車を高価買取しています。お車の購入と買取の両方をぜひ当社にご依頼ください。他店に負けない買取価格を実現します。
        </p>
      </div>

      <div id="new-car" class="service-content">
        <div class="service-title">
          <img src="@/assets/usedcar/bracket_start.webp" alt="かっこ" class="bracket">
          <h2>新車販売</h2>
          <img src="@/assets/usedcar/bracket_end.webp" alt="かっこ閉じる" class="bracket">
        </div>
        <p class="service-description">
          中古車だけでなく、新車の販売もしています。
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ServiceList"
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--sub2);
  .wrap {
    .service-content {
      display: flex;
      justify-content: space-between;
      margin-top: 60px;
      @media screen and (max-width: 767px) {
        display: block;
        margin-top: 40px;
      }
      .service-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 30%;
        height: 90px;
        margin-bottom: auto;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          width: 30%;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          height: 70px;
        }
        h2 {
          width: 70%;
          font-size: 30px;
          line-height: 30px;
          color: var(--sub1);
          text-align: center;
          @media screen and (max-width: 767px) {
            font-size: 24px;
            font-weight: 500;
          }
        }
        .auction {
          @media screen and (min-width: 768px) {
            font-size: 24px;
            font-weight: 500;
          }
        }
        .bracket {
          width: 8%;
          height: 100%;
          @media screen and (max-width: 767px) {
            width: 20px;
          }
        }
      }
      .service-description {
        width: calc(((100% - 40px) / 3) * 2 + 20px);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          width: 65%;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          margin-top: 20px;
        }
        p {
          margin: 0;
        }
        img {
          margin-top: 20px;
        }
      }
    }
    .used-car-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .used-car {
        width: calc((100% - 40px)/3);
        margin-top: 20px;
        @media screen and (max-width: 767px) {
          width: calc((100% - 20px)/2);
        }
      }
    }
    .btn-goonet {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 4;
      width: 300px;
      height: 100px;
      font-size: 18px;
      font-weight: 500;
      color: var(--main);
      background-color: var(--white);
      border: 1px solid var(--goonet);
      border-radius: 10px;
      margin: 40px auto 0 auto;
      box-shadow: 3px 3px 6px var(--goonet-shadow);
      &:hover {
        opacity: 0.8;
      }
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 55%;
      }
      @media screen and (max-width: 767px) {
        width: 90%;
        height: 80px;
        font-size: 16px;
      }
      img {
        position: absolute;
        z-index: 5;
        height: 100%;
        width: auto;
      }
    }
    .goonet-text {
      font-size: 16px;
      font-weight: 20px;
      text-align: center;
      margin-top: 10px;
    }
    .pop {
      animation: pop 2s ease-out infinite;
      opacity: 1;
    }
    @keyframes pop {
      0%, 40%, 60%, 80% {
        transform: scale(1.0);
      }
      50%, 70% {
        transform: scale(0.95);
      }
    }
    .first {
      margin-top: 0;
    }
  }
}
</style>