import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state() {
    return {
      rootUrl: "https://savage85.com",
    };
  },
  getters: {
    rootUrl(state) {
      return state.rootUrl;
    },
  },
  mutations: {
    setRootUrl(state, payload) {
      state.rootUrl = payload;
    },
  },
  actions: {
    setRootUrl({ commit }) {
      // console.log(process.env.VUE_APP_ROOT_URL);
      const rootUrl = process.env.VUE_APP_ROOT_URL;
      const payload = {
        rootUrl: rootUrl,
      };
      commit("setRootUrl", payload);
    },
  },
  plugins: [createPersistedState()],
});
