<template>
  <section>
    <div class="background">
      <img src="@/assets/top/001.webp" alt="PC背景" class="background-img pc-only">
      <img src="@/assets/top/001_sp.webp" alt="SP背景" class="background-img sp-only tablet-only">
      <div class="about-savage">
        <div class="about-savage-message">
          <div class="title">
            <h1>About SAVAGE</h1>
            <p class="sub-title">SAVAGEについて</p>
          </div>
          <p class="about-savage-description">
            岐阜市に拠点を置くSAVAGE（サベージ）は、中古車の販売・買取、自動車保険をはじめとする各種保険、レンタカー、車検・整備など自動車に関する事業を行っています。お客様とのご縁を大切にし、1つひとつの仕事を丁寧に、妥協なく、全力で取り組む、これがSAVAGEのポリシーです。お車のことならSAVAGEへご相談ください。
          </p>
          <img src="@/assets/top/003.webp" alt="SAVAGEの自動車イメージ">
        </div>
        <img src="@/assets/top/002.webp" alt="SAVAGEの自動車イメージ2">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutSavage",
}
</script>

<style lang="scss" scoped>
// 1101px 1510px
// 768px  1100px
section {
  padding: 0;
  .background {
    position: relative;
    height: 750px;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      height: 1150px;
    }
    @media screen and (max-width: 767px) {
      height: 750px;
    }
    .background-img {
      height: 100%;
    }
    .about-savage {
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 1510px;
      height: 450px;
      z-index: 2;
      @media screen and (min-width: 1101px) and (max-width: 1510px) {
        width: 1100px;
        height: 400px;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
      @media screen and (max-width: 1100px) {
        display: block;
        width: calc(100% - 40px);
        height: auto;
        left: 20px;
        right: 20px;
      }
      .about-savage-message {
        display: flex;
        align-content: space-between;
        flex-wrap: wrap;
        width: 45%;
        height: 100%;
        @media screen and (max-width: 1100px) {
          width: 100%;
          height: 450px;
          margin-bottom: 20px;
        }
        @media screen and (max-width: 767px) {
          height: 410px;
        }
        .title {
          width: 100%;
          height: fit-content;
          h1 {
            font-size: 37px;
            font-weight: 700;
            line-height: 37px;
            margin: 0;
            @media screen and (max-width: 767px) {
              font-size: 28px;
              line-height: 28px;
              text-align: center;
            }
          }
          .sub-title {
            height: auto;
            justify-content: start;
            font-size: 18px;
            font-weight: 500;
            line-height: 20px;
            margin-bottom: 0;
            @media screen and (max-width: 767px) {
              justify-content: center;
              margin-top: 10px;
            }
          }
        }
        .about-savage-description {
          height: fit-content;
          line-height: 1.7rem;
          margin: 0;
          @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 1.7rem;
          }
        }
        img {
          width: 100%;
          height: 200px;
          @media screen and (min-width: 1101px) and (max-width: 1510px) {
            height: 150px;
          }
          @media screen and (max-width: 1100px) {
            width: 100%;
            height: auto;
            aspect-ratio: 10 / 3;
          }
        }
      }
      img {
        width: auto;
        height: 100%;
        aspect-ratio: 5 / 3;
        @media screen and (min-width: 1101px) and (max-width: 1510px) {
          width: 50%;
          aspect-ratio: 5 / 3;
        }
        @media screen and (max-width: 1100px) {
          width: 100%;
          height: auto;
          aspect-ratio: 5 / 3;
        }
      }
    }
  }
}
</style>