<template>
  <section>
    <div class="wrap">
      <SectionTitle :title="title" subTitle="サービス" :black="true" />
      <div class="services">
        <div class="service">
          <img src="@/assets/insurance/insurance_01.webp" alt="中古車販売・買取" class="insurance-img">
          <h3>自動車保険</h3>
          <p>自動車保険のご提案を行っています。</p>
        </div>
        <div class="service">
          <img src="@/assets/insurance/insurance_02.webp" alt="保険(自動車保険/損害保険/生命保険)" class="insurance-img">
          <h3>損害保険</h3>
          <p>お客様の万が一に備える損害保険のご提案を行っています。</p>
        </div>
        <div class="service">
          <img src="@/assets/insurance/insurance_03.webp" alt="保険(自動車保険/損害保険/生命保険)" class="insurance-img">
          <h3>生命保険</h3>
          <p>
            お客様のニーズに合った生命保険のご提案を行っています。
          </p>
        </div>
        <div class="service aioi">
          <h3 class="insurance-handled">取扱保険</h3>
          <img src="@/assets/insurance/insurance_aioi.webp" alt="レンタカー">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SectionTitle from '@/components/Ui/SectionTitle.vue';

export default {
  name: "OurService",
  components: {
    SectionTitle,
  },
  data() {
    return {
      title: "Our Services",
      description: true,
    }
  },
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--main);
  .wrap {
    .services {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media screen and (min-width: 767px) and (max-width: 1100px) {
        justify-content: space-around;
      }
      .service {
        // position: relative;
        width: 47%;
        // height: 540px;
        // @media screen and (min-width: 801px) and (max-width: 1100px) {
        //   width: 40%;
        //   height: 450px;
        // }
        // @media screen and (min-width: 768px) and (max-width: 800px) {
        //   width: 290px;
        //   height: 500px;
        // }
        @media screen and (max-width: 767px) {
          width: 100%;
          // height: 450px;
        }
        .insurance-img {
          height: 348px;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            height: 235px;
          }
          @media screen and (max-width: 767px) {
            width: 100%;
            height: auto;
          }
        }
        h3 {
          font-size: 23px;
          color: var(--white);
          text-align: center;
          margin: 30px 0;
          @media screen and (max-width: 1100px) {
            font-size: 20px;
          }
        }
        p {
          color: var(--white);
          text-align: center;
          margin: 0;
          margin-bottom: 40px;
          @media screen and (max-width: 767px) {
            text-align: start;
          }
        }
        .service-link {
          position: absolute;
          bottom: 60px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          justify-content: center;
          align-items: center;
          width: 60%;
          height: 60px;
          color: var(--white);
          border: 1px solid var(--white);
          &:hover {
            color: var(--main);
            background-color: var(--white);
          }
        }
      }
      .aioi {
        background-color: var(--white);
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        height: 348px;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          height: 235px;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          height: auto;
          padding: 40px 0;
        }
        .insurance-handled {
          font-size: 23px;
          line-height: 3.5rem;
          background-color: var(--sub1);
          width: 90%;
          margin: 0 auto 20px auto;
        }
        img {
          width: 75%;
          margin: auto;
        }
      }
    }
  }
}
</style>