<template>
  <div>
    <PageTitle :title="title" :description="description" />
    <InformationVue />
    <ContactVue />
  </div>
</template>

<script>
import PageTitle from '@/components/Ui/PageTitle.vue';
import InformationVue from '@/components/Store/Information.vue';
import ContactVue from "@/components/Top/Contact.vue";

export default {
  name: "StoreVue",
  components: {
    PageTitle,
    InformationVue,
    ContactVue,
  },
  data() {
    return {
      title: "Company <br class='sp-only'>& Shop Information",
      description: "会社・店舗情報",
    }
  },
}
</script>

<style lang="scss" scoped>
</style>