<template>
  <section>
    <UiSliderBox class="slider" :images="images" />
    <div class="wrap">
      <p class="description">
        岐阜市・羽島市にショップがあるSAVAGE（サベージ）は、中古車の販売、お客様がほしいお車をオークションで仕入れて販売するオークション代行、中古車の買取、新車の販売を行っています。中古車をお求めの方は、他店と価格を比較してみてください。価格に自信があります。ぜひお気軽にお問い合わせ・ご相談ください。
      </p>
    </div>
  </section>
</template>

<script>
import UiSliderBox from '@/components/Ui/SliderBox.vue';

export default {
  name: "TopSwiper",
  components: {
    UiSliderBox,
  },
  data() {
    return {
      images: [
        { id: 1, imgpath: "slider_01.webp", name: "SAVAGEの中古車販売" },
        { id: 2, imgpath: "slider_02.webp", name: "ポルシェ" },
        { id: 3, imgpath: "slider_03.webp", name: "レンジローバー" },
        { id: 4, imgpath: "slider_04.webp", name: "SAVAGEは価格に自信があります" },
        { id: 5, imgpath: "slider_05.webp", name: "SAVAGEはオークション代行、中古車の買取も行っています" },
        { id: 6, imgpath: "slider_06.webp", name: "ポルシェ 運転席" },
        { id: 7, imgpath: "slider_07.webp", name: "フォード マスタング" },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.description {
  margin: 60px auto 0 auto;
  width: 800px;
  @media screen and (max-width: 767px) {
    margin: 20px auto 0 auto;
    width: 100%;
  }
}
</style>