<template>
  <div>
    <PageTitle :title="title" :description="description" />
    <InsuranceTop />
    <OurService />
    <FeaturesVue />
    <Flow />
    <ContactVue />
  </div>
</template>

<script>
import PageTitle from '@/components/Ui/PageTitle.vue';
import InsuranceTop from '@/components/Insurance/Top.vue';
import OurService from '@/components/Insurance/OurService.vue';
import FeaturesVue from '@/components/Insurance/Features.vue';
import Flow from '@/components/Insurance/Flow.vue';
import ContactVue from "@/components/Top/Contact.vue";

export default {
  name: "InsuranceVue",
  components: {
    PageTitle,
    InsuranceTop,
    OurService,
    FeaturesVue,
    Flow,
    ContactVue,
  },
  data() {
    return {
      title: "Insurance",
      description: "SAVAGEの自動車/損害/生命保険",
    }
  },
}
</script>

<style lang="scss" scoped>
</style>