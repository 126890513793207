<template>
  <div class="layout-header">
    <div
      class="header"
      :class="{
        'over-baseline':
          (scrollY >= 1000 && path === '/') || (scrollY >= 0 && path !== '/'),
      }"
    >
      <div class="header-cover">
        <div class="header-left">
          <div class="to-top" @click="top">
            <img src="@/assets/top/logo.webp" alt="SAVAGE ロゴ" />
          </div>
        </div>

        <div class="header-right">
          <div class="phone-contact" v-scroll-to="'#phone'">
            <img
              src="@/assets/top/phone_1.webp"
              alt="お電話でのお問い合わせ"
            />
          </div>
          <div class="mail-contact" v-scroll-to="'#form'">
            <img
              src="@/assets/top/mail_1.webp"
              alt="メールでのお問い合わせ"
            />
          </div>
          <div class="mail-contact" v-scroll-to="'#fax'">
            <img src="@/assets/top/fax_1.webp" alt="FAXでのお問い合わせ" />
          </div>
          <div class="hamburger">
            <UiDrawer />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiDrawer from "@/components/Ui/Drawer.vue";

export default {
  name: "LayoutHeader",
  components: {
    UiDrawer,
  },
  props: {
    path: String,
  },
  data() {
    return {
      scrollY: 0,
      scrollTimer: null,
      //  documentheight:0,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scrollTimer === null) {
        // console.log(document.body.clientHeight);
        this.documentheight = document.body.clientHeight;
        this.scrollTimer = setTimeout(
          function () {
            this.scrollY = window.scrollY;
            // console.log(this.documentheight - this.scrollY);
            clearTimeout(this.scrollTimer);
            this.scrollTimer = null;
            // console.log(this.scrollY);//長さはかるコンソール
          }.bind(this),
          200
        );
      }
    },
    top() {
      if (this.$route.path !== "/") {
        //遷移する
        this.$router.push("/");
      } else {
        this.$scrollTo("#app", 500, { easing: "ease" });
        // alert('トップページ');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.st0 {
  fill: var(--main);
}
.st1 {
  fill: none;
}

.header {
  background-color: var(--main);
  width: 100vw;
  height: 75px;
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s;
  top: 0;
  left: 0;
  .header-cover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 3%;
    margin-left: 2%;
    width: 96%;
    margin-bottom: 0;
  }
  .header-left {
    width: auto;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 767px) {
      width: auto;
    }
    .to-top {
      cursor: pointer;
      img {
        height: 60px;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          width: 70%;
          height: 100%;
          margin: auto 0;
        }
        @media screen and (max-width: 767px) {
          width: 80%;
          height: 100%;
          margin: auto 0;
        }
      }
    }
    .to-top:hover {
      opacity: 0.8;
    }
  }
  .header-right {
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      width: 700px;
      margin-right: 1%;
    }
    @media screen and (max-width: 767px) {
      width: 30%;
      justify-content: flex-end;
      padding-right: 10px;
    }
    a {
      text-decoration: none;
      color: var(--main);
    }
    .phone-contact,
    .mail-contact {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      @media screen and (min-width: 1101px) {
        display: flex;
      }
      img {
        width: auto;
        height: 50px;
        margin: auto;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          height: 40px;
        }
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }
  }
}
</style>
