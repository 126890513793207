<template>
  <section>
    <div class="wrap">
      <SectionTitle :title="title" subTitle="SAVAGEの車検・自動車整備の特徴" :black="false" />
      <div class="features">
        <UiFeatures 
          v-for="feature in features" 
          :key="feature.id" 
          :imgPath="feature.imgPath"
          :imgAlt="feature.imgAlt"
          :title="feature.title"
          :description="feature.description"
        />
      </div>
    </div>
  </section>
</template>

<script>
import SectionTitle from '@/components/Ui/SectionTitle.vue';
import UiFeatures from '@/components/Ui/Features.vue';

export default {
  name: "FeaturesVue",
  components: {
    SectionTitle,
    UiFeatures,
  },
  data() {
    return {
      title: "Features",
      features: [
        {
          id: 1,
          imgPath: "insurance_01.webp",
          imgAlt: "SAVAGEの車検・自動車整備の特徴 1",
          title: "コストが低い",
          description: "他社よりも比較的安価かつ安心安全なサービスを提供しております。"
        },
        {
          id: 2,
          imgPath: "insurance_02.webp",
          imgAlt: "SAVAGEの車検・自動車整備の特徴 2",
          title: "フォローが充実",
          description: "整備や修理だけでなく、エアロパーツ作成や長年自動車整備に携わってきたスタッフによるアドバイスや相談も可能です。"
        },
        {
          id: 3,
          imgPath: "insurance_03.webp",
          imgAlt: "SAVAGEの車検・自動車整備の特徴 3",
          title: "幅広く相談できる",
          description: "自家用車1台などの注文からトラックや社用車の車検や整備など幅広く相談や注文が可能です。"
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
section {
  .wrap {
    .features {
      .feature {
        display: flex;
        justify-content: center;
        padding: 20px 0;
        margin-bottom: 20px;
        background-color: var(--sub2);
        .feature-logo {
          width: 15%;
          height: auto;
          aspect-ratio: 33 / 37;
          margin: auto 0;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            width: 18%;
          }
          @media screen and (max-width: 767px) {
            width: 20%;
            margin-right: 5%;
          }
        }
        .feature-message {
          width: 60%;
          height: auto;
          padding-left: 5%;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            width: 70%;
          }
          @media screen and (max-width: 767px) {
            width: auto;
            padding: 0 20px;
          }
          .feature-title {
            font-size: 23px;
            color: var(--sub1);
            @media screen and (max-width: 767px) {
              display: flex;
              align-items: center;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
</style>