<template>
  <section>
    <div class="wrap">
      <div class="content">
        <div class="infomations">
          <h3 class="information-title company-info">
            会社情報
          </h3>
          <div class="information-rows">
            <div v-for="row in companyInfo" :key="row.title">
              <TableRow :title="row.title" :description="row.description" />
            </div>
          </div>
          <h3 class="information-title">
            本店
          </h3>
          <div class="information-rows">
            <div v-for="row in headStore" :key="row.title">
              <TableRow :title="row.title" :description="row.description" />
            </div>
          </div>
          <h3 class="information-title">
            薮田南店
          </h3>
          <div class="information-rows">
            <div v-for="row in yabutaminamiStore" :key="row.title">
              <TableRow :title="row.title" :description="row.description" />
            </div>
          </div>
          <h3 class="information-title">
            間島ヤード
          </h3>
          <div class="information-rows">
            <div v-for="row in kantouyado" :key="row.title">
              <TableRow :title="row.title" :description="row.description" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TableRow from '@/components/Ui/InformationTableRow.vue';

export default {
  name: "InformationVue",
  components: {
    TableRow,
  },
  data() {
    return {
      // 会社情報
      companyInfo: [
        {title: "会社名", description: "株式会社R.C.I.<br class='sp-only'>（アールシーアイ）"},
        {title: "代表者名", description: "川上 貴史"},
        {title: "住所", description: "〒461-0005 <br class='sp-only'>愛知県名古屋市東区東桜1-10-36"},
        {title: "電話番号", description: "<p><a href='tel:0583747520' class='phone-number'>058-374-7520</a><br class='sp-only'/>（平日10時〜19時）</p>"},
      ],
      // 本店
      headStore: [
        {title: "住所", description: "〒501-6244 <br class='sp-only'>岐阜県羽島市竹鼻町丸の内11丁目52番"},
        {title: "電話番号", description: "<p><a href='tel:0583747520' class='phone-number'>058-374-7520</a><br class='sp-only'/>（平日10時〜19時）</p>"},
        {title: "FAX番号", description: "<a class='phone-number'>058-338-0472</a>"},
        {title: "営業時間", description: "10時～19時<br class='sp-only'>（定休日：水曜）"},
      ],
      // 薮田南店
      yabutaminamiStore: [
        {title: "住所", description: "〒500-8384 <br class='sp-only'>岐阜市薮田南4丁目"},
        {title: "営業時間", description: "ご予約専用です。<br class='sp-only'>事前にお問い合わせをお願いいたします。"},
      ],
      // 間島ヤード
      kantouyado: [
        {title: "住所", description: "〒501-6251 <br class='sp-only'>羽島市福寿町間島5丁目13番"},
        {title: "営業時間", description: "10時～19時<br class='sp-only'>（定休日：水曜）"},
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
section {
  .wrap {
    .content {
      @media screen and (max-width: 767px) {
        padding: 0;
      }
      .infomations {
        .information-title {
          font-size: 18px;
          text-align: center;
          line-height: 3.5rem;
          margin: 0;
          color: var(--white);
          background-color: var(--sub1);
        }
        .company-info {
          background-color: var(--main);
        }
        .information-rows {
          margin-bottom: 60px;
          @media screen and (max-width: 767px) {
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}
</style>