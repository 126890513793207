<template>
  <div>
    <!-- 
    | Swiper
    | 
    | slidesPerView   プレビューの枚数
    | spaceBetween    画像同士のスペースサイズ
    | :speed          切り替わるアニメーションの速さ
    | :effect         プレビュー画像を斜めにする
    | :modules        AutoPlay, EffectCoverflowを読み込む
    | :autoplay       画像を自動で切り替える（delay: 切り替わるまでの時間の設定, disableOnInteraction: ユーザー操作後に自動再生を止める）
    | :loop           画像をループさせる
    -->
    <!-- SP -->
     <div v-if="spWatch" class="sp-slider">
      <Swiper
        slidesPerView="1.3"
        spaceBetween="20"
        :centeredSlides="true"
        :speed="750"
        :modules="modules"
        :autoplay="{delay: 2000, disableOnInteraction: false,}"
        :loop="true"
      >
        <SwiperSlide v-for="image in images" :key="image.id" >
          <img :src="require(`@/assets/usedcar/${image.imgpath}`)" :alt="image.name" />
        </SwiperSlide>
      </Swiper>
     </div>

    <!-- PC -->
    <Swiper
      v-else
      slidesPerView="3"
      spaceBetween="30"
      :speed="750"
      :effect="'coverflow'"
      :modules="modules"
      :autoplay="{delay: 3000}"
      :loop="true"
    >
      <SwiperSlide v-for="image in images" :key="image.id" >
        <img :src="require(`@/assets/usedcar/${image.imgpath}`)" :alt="image.name" />
      </SwiperSlide>
    </Swiper>

  </div>
</template>

<script>
/* Swiper */
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';

import { ref } from 'vue';

export default {
  name: "slider-box",
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    images: Array,
  },
  data() {
    return {
      spWatch: true,
      modules: [Autoplay, EffectCoverflow],
    }
  },
  mounted() {
    this.displaySize();
  },
  methods: {
    displaySize() {
      var screenWidth = ref(window.innerWidth); // 初期値として画面幅を設定
      screenWidth.value = window.innerWidth;
      if (screenWidth.value <= 1100) {
        this.spWatch = true;
      } else {
        this.spWatch = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  overflow: visible; // 要検討
}
</style>
