<template>
  <section>
    <div class="wrap">
      <SectionTitle :title="title" subTitle="お問い合わせからご利用の流れ" :black="false" />
      <div class="flows">
        <div class="flow">
          <div class="flow-number">STEP 1</div>
          <div class="flow-content">
            <h3 class="flow-title">お問い合わせ</h3>
            <p class="flow-description">
              当ホームページ内にあるお問い合わせフォーム、または、お電話（<a href="tel:0583747520" class="phone-number">058-374-7520</a>）にて、ご希望のサービスについてご連絡をお願いします。
            </p>
          </div>
        </div>
        <div class="flow">
          <div class="flow-number">STEP 2</div>
          <div class="flow-content">
            <h3 class="flow-title">担当者にてヒアリング</h3>
            <p class="flow-description">
              いただいたお問い合わせ内容ごとにお電話、または、メールで弊社担当からご連絡を差し上げます。ご希望についてヒアリングをさせていただきます。
            </p>
          </div>
        </div>
        <div class="flow last">
          <div class="flow-number">STEP 3</div>
          <div class="flow-content">
            <h3 class="flow-title">次のステップについて案内</h3>
            <p class="flow-description">
              ヒアリングをしましたら、次のステップについて、お客様個別で案内をさせていただきます。このように、お客様個別で最適な対応をさせていただきます。
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SectionTitle from '@/components/Ui/SectionTitle.vue';

export default {
  name: "FlowVue",
  components: {
    SectionTitle,
  },
  data() {
    return {
      title: "Flow",
    }
  },
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--sub2);
  .wrap {
    .flows {
      .flow {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        @media screen and (max-width: 767px) {
          display: block;
          width: 100%;
          margin-bottom: 30px;
        }
        .flow-number {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20%;
          height: 60px;
          font-size: 20px;
          font-weight: 500;
          margin-bottom: auto;
          color: var(--white);
          background-color: var(--sub1);
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
        .flow-content {
          width: 75%;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
          .flow-title {
            font-size: 25px;
            margin-top: 0;
            margin-bottom: 10px;
            @media screen and (max-width: 767px) {
              text-align: center;
              font-size: 20px;
              margin: 20px 0;
            }
          }
          .flow-description {
            margin: 0;
          }
          .flow-title,
          .flow-description {
            color: var(--main);
          }
        }
      }
      .last {
        margin-bottom: 0;
      }
    }
  }
}
</style>