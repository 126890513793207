<template>
  <div id="fv" class="top">
      <video ref="player" class="top-video pc-only" autoplay muted playsinline loop>
        <source src="/video/savage85_top.mp4" />
        このブラウザではサポートされていません。
      </video>
      <video ref="player" class="top-video tablet-only" autoplay muted playsinline loop>
        <source src="/video/savage85_top_tablet.mp4" />
        このブラウザではサポートされていません。
      </video>
      <video ref="player" class="top-video sp-only" autoplay muted playsinline loop>
        <source src="/video/savage85_top_sp.mp4" />
        このブラウザではサポートされていません。
      </video>
    </div>
</template>

<script>
export default {
  name: "TopVideoVue",
}
</script>

<style lang="scss" scoped>
.top {
  // 子要素の高さと合わせる
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 75px;
  
  .top-video {
    width: 100vw;
    @media screen and (max-width: 767px) {
      height: auto;
    }
  }
}
</style>