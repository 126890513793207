<template>
  <section>
    <div class="wrap">
      <SectionTitle :title="title" subTitle="サービス" :black="true" />
      <div class="services">
        <div class="service">
          <img src="@/assets/maintenance/service_01.webp" alt="中古車販売・買取">
          <h3>車検</h3>
        </div>
        <div class="service">
          <img src="@/assets/maintenance/service_02.webp" alt="保険(自動車保険/損害保険/生命保険)">
          <h3>板金・塗装</h3>
        </div>
        <div class="service">
          <img src="@/assets/maintenance/service_03.webp" alt="レンタカー">
          <h3>修理・整備</h3>
        </div>
        <div class="service">
          <img src="@/assets/maintenance/service_04.webp" alt="車検・自動車整備">
          <h3>カー用品販売</h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SectionTitle from '@/components/Ui/SectionTitle.vue';

export default {
  name: "OurService",
  components: {
    SectionTitle,
  },
  data() {
    return {
      title: "Our Services",
      description: true,
    }
  },
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--main);
  .wrap {
    .services {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .service {
        width: 47%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        h3 {
          font-size: 23px;
          color: var(--white);
          text-align: center;
          margin: 30px 0 60px 0;
          @media screen and (max-width: 1100px) {
            font-size: 20px;
            margin: 20px 0 40px 0;
          }
        }
        .service-link {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 60%;
          height: 60px;
          margin: auto;
          color: var(--white);
          border: 1px solid var(--white);
          cursor: pointer;
          &:hover {
            color: var(--main);
            background-color: var(--white);
          }
        }
      }
    }
  }
}
</style>