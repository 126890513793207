<template>
  <div>
    <PageTitle :title="title" :description="description" />
    <Top />
    <FeaturesVue />
    <PriceVue />
    <FlowVue />
    <ContactVue />
  </div>
</template>

<script>
import PageTitle from '@/components/Ui/PageTitle.vue';
import Top from '@/components/RentACar/Top.vue';
import FeaturesVue from '@/components/RentACar/Features.vue';
import PriceVue from '@/components/RentACar/Price.vue';
import FlowVue from "@/components/RentACar/Flow.vue";
import ContactVue from "@/components/Top/Contact.vue";

export default {
  name: "RentACar",
  components: {
    PageTitle,
    Top,
    FeaturesVue,
    PriceVue,
    FlowVue,
    ContactVue,
  },
  data() {
    return {
      title: "Rent-a-car",
      description: "SAVAGEのレンタカーサービス",
    }
  },
}
</script>

<style lang="scss" scoped>
</style>