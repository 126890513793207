<template>
  <div class="section-title">
    <div v-if="black===true">
      <img class="crown" src="@/assets/top/section_title_logo.webp" alt="白のクラウン">
      <h2 class="bg-black" v-html="title"></h2>
      <p class="bg-black">{{ subTitle }}</p>
    </div>
    <div v-else>
      <img class="crown" src="@/assets/top/section_title_logo_black.webp" alt="黒のクラウン">
      <h2 class="bg-white" v-html="title"></h2>
      <p class="bg-white">{{ subTitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionTitle",
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: String,
    black: Boolean,
  }
}
</script>

<style lang="scss" scoped>
.section-title {
  margin-bottom: 60px;
  @media screen and (max-width: 767px) {
    margin-bottom: 40px;
  }
  .crown {
    width: 50px;
    margin: auto;
  }
  h2 {
    font-size: 37px;
    font-weight: 500;
    line-height: 40px;
    margin: 10px 0;
    @media screen and (max-width: 767px) {
      font-size: 28px;
      line-height: 28px;
      text-align: center;
    }
  }
  p {
    text-align: center;
    margin: 0;
  }
  .bg-black {
    color: var(--white);
  }
  .bg-white {
    color: var(--main);
  }
}
</style>