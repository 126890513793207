<template>
  <section>
    <div class="wrap">
      <div class="content">
        <img src="@/assets/rentacar/top.webp" alt="SAVAGEのレンタカーは軽自動車や輸入車など幅広い車種からお選びいただけます" class="top-img">
        <p class="top-message">
          岐阜市・羽島市にショップがあるSAVAGE（サベージ）のレンタカーサービスは、軽自動車や輸入車など幅広い車種からお客様が乗りたい、乗ってみたい車を選んでレンタルしていただくことができます。SAVAGEならではの珍しい高級車をレンタカーとしてご用意しています。SAVAGEのレンタカーで日々の生活にちょっとした楽しみを取り入れてみてください。
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "InsuranceTop"
}
</script>

<style lang="scss" scoped>
section {
  padding-bottom: 0;
  .wrap {
    .content {
      @media screen and (max-width: 767px) {
        padding: 0;
      }
      .top-message {
        margin-top: 40px;
        margin-bottom: 0;
        @media screen and (max-width: 767px) {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>