<template>
  <div class="table-row">
    <div class="row title" v-html="title"></div>
    <div class="row description" v-html="description"></div>
  </div>
</template>

<script>
export default {
  name: "InformationTableRow",
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
  }
}
</script>

<style lang="scss" scoped>
  .table-row {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid var(--main);
    .row {
      height: 40px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 767px) {
        height: auto;
      }
    }
    .title {
      width: 20%;
      justify-content: center;
      border-right: 1px solid var(--main);
      @media screen and (max-width: 767px) {
        width: 35%;
      }
    }
    .description {
      width: 80%;
      padding-left: 5%;
      @media screen and (max-width: 767px) {
        width: 65%;
        text-wrap: wrap;
      }
    }
  }
</style>