<template>
  <section>
    <div class="wrap">
      <SectionTitle :title="title" subTitle="代表からのメッセージ" :black="true" />
      <div class="message-section">
        <div class="representative">
          <img class="representative-logo" src="@/assets/top/representative.webp" alt="代表 川上貴史">
          <img class="savage-logo" src="@/assets/top/logo004.webp" alt="SAVAGEロゴ">
        </div>
        <div class="message">
          <p class="representative-message">
            SAVAGEを運営する株式会社R.C.I.（アールシーアイ）の川上です。私は、生まれたときから車が大好きで、どんなに生活が苦しいときも、車だけは譲れず、毎年買い替えてきたほどの車好きです。そんな私は、大好きな車に関わる仕事をしたい、車に囲まれて生きていきたいと夢を描き、今、SAVAGEとして車に関わるお仕事をしています。お車のことでお客様にお役に立てることはすべてしたい。これが私たちの思いです。これまで、車が好きなことで頂いたご縁、この仕事を通して頂いたご縁がたくさんあります。この仕事ができる喜びを胸に、日々お客様との出会いを楽しみ、お客様にご満足いただき、喜んでいただける、そんなサービスを社員一丸となり提供していきます。
          </p>
          <p class="representative-name">
            <span class="representative-post">代表取締役</span>川上貴史
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SectionTitle from '@/components/Ui/SectionTitle.vue';

export default {
  name: "RepresentativeMessage",
  components: {
    SectionTitle,
  },
  data() {
    return {
      title: "Message from <br class='sp-only'>a Representative",
    }
  },
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--main);
  .wrap {
    .message-section {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        display: block;
      }
      .representative  {
        position: relative;
        width: 48%;
        @media screen and (max-width: 767px) {
          width: 100%;
          margin-bottom: 40px;
        }
        .representative-logo {
          margin-bottom: auto;
        }
        .savage-logo {
          position: absolute;
          width: 65%;
          top: -45%;
          left: -40%;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            width: 55%;
            left: -20%;
            top: -35%;
          }
          @media screen and (max-width: 767px) {
            width: 55%;
            top: -47%;
            left: -20%;
          }
        }
      }
      .message {
        display: flex;
        align-content: space-between;
        flex-wrap: wrap;
        width: 48%;
        height: auto;
        min-height: 360px;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        p {
          color: var(--white);
        }
        .representative-message {
          width: 100%;
          margin-top: 0;
        }
        .representative-name {
          font-size: 18px;
          @media screen and (max-width: 767px) {
            margin-top: 10px;
          }
          .representative-post {
            margin-right: 2rem;
          }
        }
      }
    }
  }
}
</style>