<template>
  <section>
    <div class="wrap">
      <SectionTitle :title="title" subTitle="サービス" :black="true" />
      <div class="services">
        <div class="service">
          <img src="@/assets/top/004.webp" alt="中古車販売・買取">
          <h3>中古車の販売・買取</h3>
          <p>中古車の販売、お客様が欲しいお車をオークションで仕入れて販売するオークション代行、中古車の買取、新車の販売を行っています。</p>
          <router-link to="/used-car" class="service-link animation-btn bgskew"><span>詳細を見る</span></router-link>
        </div>
        <div class="service">
          <img src="@/assets/top/005.webp" alt="保険(自動車保険/損害保険/生命保険)">
          <h3>保険<br class="sp-only tablet-only">（自動車保険/損害保険/生命保険）</h3>
          <p>
            保険（自動車/損害/生命）のオーダーメイドプランをご提案いたします。お客様に本当に必要な保険を二人三脚で作り上げるプランで、万が一の場合にも安心をお約束します。
          </p>
          <router-link to="/insurance" class="service-link animation-btn bgskew"><span>詳細を見る</span></router-link>
        </div>
        <div class="service">
          <img src="@/assets/top/006.webp" alt="レンタカー">
          <h3>レンタカー</h3>
          <p>当社のレンタカーサービスは、軽自動車や輸入車など幅広い車種からお客様が乗りたい、乗ってみたい車を選んでレンタルしていただくことができます。</p>
          <router-link to="/rent-a-car" class="service-link animation-btn bgskew"><span>詳細を見る</span></router-link>
        </div>
        <div class="service">
          <img src="@/assets/top/007.webp" alt="車検・自動車整備">
          <h3>車検・自動車整備</h3>
          <p>
            車検・自動車整備をしています。無駄なコストをかけず、充実したフォロー体制をご用意して車検と自動車整備を対応いたします。
          </p>
          <router-link to="/maintenance" class="service-link animation-btn bgskew"><span>詳細を見る</span></router-link>
        </div>
        <div class="service">
          <img src="@/assets/top/008.webp" alt="自動車の陸送">
          <h3>自動車の陸送</h3>
          <p>
            法人・個人向けの自動車陸送を行なっています。愛知県を拠点に全国対応、徹底した安全管理とドライバー教育のもと、お客様に安心してご依頼いただける環境を整えています。
          </p>
          <div class="rci-link">
            <a class="rci-btn animation-btn bgskew" href="https://rci85.com" target="_blank"><span>詳細を見る</span></a>
            <p class="rci-description">自動車の陸送事業は「R.C.I.（アール・シー・アイ）」<br class="sp-only">名義で運営しています。</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SectionTitle from '@/components/Ui/SectionTitle.vue';

export default {
  name: "OurService",
  components: {
    SectionTitle,
  },
  data() {
    return {
      title: "Our Services",
      description: true,
    }
  },
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--main);
  .wrap {
    .services {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .service {
        position: relative;
        width: 47%;
        height: 680px;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          height: 650px;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          height: 600px;
        }
        h3 {
          font-size: 23px;
          color: var(--white);
          text-align: center;
          margin: 30px 0;
          @media screen and (max-width: 1100px) {
            font-size: 20px;
          }
        }
        p {
          color: var(--white);
          margin: 0;
          margin-bottom: auto;
        }
        .service-link {
          position: absolute;
          bottom: 80px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          justify-content: center;
          align-items: center;
          width: 60%;
          height: 60px;
          color: var(--white);
          border: 1px solid var(--white);
          @media screen and (max-width: 767px) {
            bottom: 50px;
          }
          &:hover {
            color: var(--main);
          }
        }
        .rci-link {
          .rci-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60%;
            height: 60px;
            border: 1px solid var(--white);
            margin: 40px auto 0 auto;
            color: var(--white);
            &:hover {
              color: var(--main);
            }
          }
          .rci-description {
            font-size: 14px;
            text-align: center;
            margin-top: 15px;
          }
        }
      }
    }
  }
}
</style>